<!--  -->
<template>
  <div class="">
    <div class="historychoose" v-show="$route.meta.showfater">
      <!-- <el-tabs :tab-position="tabPosition" type="border-card">
  <el-tab-pane label="单导联心电" class="history_tab"> -->
      <h2>请选择日期范围查询<span>{{ realName }}</span><span style="color:#4da1eb">单导联Holter</span>心电检测记录</h2>
      <el-form ref="form" :model="form">
        <el-col :span="2">
          <div class="blank"></div>
        </el-col>
        <el-form-item>
          <span slot="label">
            <span class="form-name"> 选择起始日期 </span>
          </span>
          <el-col :span="11" class="blank">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.date1" style="width: 100%"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-col :span="2">
          <div class="blank"></div>
        </el-col>
        <el-form-item>
          <span slot="label">
            <span class="form-name"> 选择截止日期 </span>
          </span>
          <el-col :span="11" class="blank">
            <el-date-picker type="date" placeholder="选择日期" v-model="form.date2" style="width: 100%"></el-date-picker>
          </el-col>
        </el-form-item>
        <el-col :span="2">
          <div class="blank"></div>
        </el-col>
        <el-form-item>
          <el-button class="xingou-blue" type="primary" @click="querylistByTime()">确认查询</el-button>
          <el-button type="primary" plain @click="queryAll()">查询所有记录</el-button>

          <el-button type="success" @click="download_table()">导出</el-button>
        </el-form-item>
      </el-form>

      <!-- 添加搜索\刷新功能 -->
      <!-- <div style="text-align: right">
        <el-button circle size="small"><i class="el-icon-search"></i></el-button>
        <el-button circle size="small"><i class="el-icon-refresh"></i></el-button>
        <el-button circle size="small"><i class="el-icon-s-grid"></i></el-button>
      </div> -->
      <!-- 表格内容 -->
      <el-table :data="tableData" height="600" :header-cell-style="tableHeaderColor" border stripe style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }" :cell-style="{ fontSize: '16px' }" v-loading="loadingShow"
        element-loading-text="数据正在加载中">
        <el-table-column type="index" align="center">
        </el-table-column>
        <el-table-column prop="createTime" label="记录时间" align="center" sortable>
        </el-table-column>
        <el-table-column prop="totalTime" label="总时长" align="center">
        </el-table-column>
        <el-table-column prop="read" label="Holter记录" align="center">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="medium" style="font-size: 16px;">查看记录
            </el-button>
          </template>
        </el-table-column>

        <!-- 复核模块 -->
        <el-table-column prop="check" label="咨询" align="center" v-if="isCrossPlatJump">
          <template slot-scope="scope">
            <el-popover placement="right" trigger="click">
              <p>输入您的症状描述</p>
              <div style="text-align: right; margin: 0">
                <el-input v-model="scope.row.userDescribe" type="textarea" placeholder="添加症状描述"></el-input>
                <el-button size="mini" type="text">取消</el-button>
                <el-button type="primary" size="mini" @click="Check2Doctor(scope.row)">确定</el-button>
              </div>
              <el-button type="primary" size="medium" style="font-size: 14px;" slot="reference">医生复核
              </el-button>
            </el-popover>
            <el-button type="primary" plain size="medium" style="font-size: 14px;" @click=CheckResult(scope.row)>查看复核
            </el-button>
          </template>
        </el-table-column>

        <el-table-column prop="broadcast" label="播报结论" align="center">
          <template slot-scope="scope">
            <el-button @click="handleBroadcast(scope.row)" type="text" size="medium" style="font-size: 16px;">查看结论
            </el-button>
          </template>
        </el-table-column>
        <el-table-column prop="read" label="报告" align="center">
          <template slot-scope="scope">
            <el-button @click="routeReport(scope.row)" type="text" size="medium" style="font-size: 16px;">查看报告
            </el-button>
          </template>
        </el-table-column>
        <!-- 备注模块 -->
        <el-table-column label="备注" :span="24">
          <template slot-scope="scope">
            <el-col :span="18">
              <el-input :rows="1" placeholder="请输入内容" v-model="scope.row.remark">
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-button @click="updateRemark(scope.row)" type="primary">保存</el-button>
            </el-col>
          </template>
        </el-table-column>
        <el-table-column label="删除" align="center">
          <template slot-scope="scope">
            <!-- <el-button
            size="mini"
            type="danger"
            @click.native.prevent="handleDelete(scope.$index, tableData)">删除</el-button> -->
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="handleDelete(scope.$index, scope.row)">
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="查看复核结果" :visible.sync="checkdataListVisible">
        <!-- <p>查看复核结果</p> -->
        <el-table :data="checkdataList">
          <el-table-column prop="createTime" label="申请复核时间"></el-table-column>
          <el-table-column prop="status" label="复核状态" sortable
            :filters="[{ text: '已复核', value: '1' }, { text: '待复核', value: '0' }]" :filter-method="filterTag"
            filter-placement="bottom-end">
            <template slot-scope="scope">
              <el-tag :type="scope.row.status === '0' ? 'warning' : 'success'" disable-transitions
                style="font-size: 14px;">{{ scope.row.status === '0' ? '待复核' : '已复核' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="checkTime" label="复核完成时间"></el-table-column>
          <el-table-column prop="userDescribe" label="症状描述"></el-table-column>
          <el-table-column prop="conclusion" label="复核结论"></el-table-column>
        </el-table>
        <el-button @click="checkdataListVisible = false">取 消</el-button>
        <el-button type="primary" @click="checkdataListVisible = false">确 定</el-button>
      </el-dialog>
      <div v-if="dialogVisible"> <!-- 关闭后销毁dialog框 -->
        <el-dialog title="播报记录" :visible.sync="dialogVisible" width="70%" :before-close="handleClose"
          :close-on-click-modal="false">
          <!-- 在遮罩空白处点击。可以通过:close-on-click-modal='false'来关闭这个功能 -->
          <div><el-switch v-model="showBar" active-text="统计图" inactive-text="详细信息"></el-switch></div>
          <!-- showBar==false 显示详细20结论 -->
          <span v-if="!showBar">下面是您每20秒检测一次的心电图结论</span>
          <el-table v-if="!showBar" :data="broadcastContent" border height="500" max-height="400"
            :default-sort="{ prop: 'date', order: 'descending' }" :cell-style="cellStyle" v-loading="pictLoading"
            element-loading-background=white element-loading-text="数据正在加载中" element-loading-spinner="el-icon-loading">
            <el-table-column prop="time" label="时间" align="center" min-width="25%" sortable>
            </el-table-column>
            <el-table-column prop="xinlv" label="心率(次/分钟)" align="center" min-width="8%">
            </el-table-column>
            <el-table-column label="疑似度(%)" align="center">
              <el-table-column prop="xdgs" label="窦性心动过速" align="center" min-width="10%">
              </el-table-column>
              <el-table-column prop="xdgh" label="窦性心动过缓" align="center" min-width="10%">
              </el-table-column>
              <el-table-column prop="xlbq" label="窦性心律不齐" align="center" min-width="10%">
              </el-table-column>
              <!-- <el-table-column prop="dxtb" label="窦性停搏" align="center" min-width="8%">
              </el-table-column> -->
              <el-table-column prop="fxzb" label="房性早搏" align="center" min-width="8%">
              </el-table-column>
              <el-table-column prop="jjxzb" label="交界性早搏" align="center" min-width="10%">
              </el-table-column>
              <el-table-column prop="sxzb" label="室性早搏" align="center" min-width="8%">
              </el-table-column>
              <el-table-column prop="ssxxdgs" label="室上性心动过速" align="center" min-width="12%">
              </el-table-column>
              <el-table-column prop="sxxdgs" label="室性心动过速" align="center" min-width="10%">
              </el-table-column>
              <el-table-column prop="fxyb" label="交界性逸博" align="center" min-width="10%">
              </el-table-column>
              <el-table-column prop="sxyb" label="室性逸博" align="center" min-width="8%">
              </el-table-column>
              <el-table-column prop="fc" label="房颤" align="center" min-width="8%">
              </el-table-column>
              <el-table-column prop="qtyc_map" label="心梗相关疾病" align="center" min-width="8%">
              </el-table-column>
            </el-table-column>
          </el-table>
          <!-- showBar==true 显示条形图 -->
          <div id="BroadcastBar" v-show="showBar" style="width:90%; height:400px;margin: 10px;">
          </div>
          <div style="display: flex; justify-content: center;"> <!-- 居中-->
            <el-button style="text-align: center" type="primary" size="small"
              @click="dialogVisible = false">关闭</el-button>
          </div>
        </el-dialog>
      </div>

    </div>
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Cookies from "js-cookie";

export default {
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true,
      dialogVisible: false,
      tabPosition: "left",
      checkdataListVisible: false,
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      tableData: [],
      checkdataList: [],
      broadcastContent: [],
      pictLoading: false,
      header: {},
      isCrossPlatJump: true,
      loadingShow: true,
      realName: '',
      showBar: true,
      BroadcastBar_option: {}
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    TimeProcess(time) {
      let y = time.getFullYear();
      let MM = time.getMonth() + 1;
      MM = MM < 10 ? ('0' + MM) : MM;
      let d = time.getDate();
      d = d < 10 ? ('0' + d) : d;
      return y + '-' + MM + '-' + d
    },
    querylistByTime() {
      this.loadingShow = true
      this.startTime = this.TimeProcess(this.form.date1)
      this.endTime = this.TimeProcess(this.form.date2)
      if (this.$store.state.module.crossPlatform) {
        console.log("object11111");
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http.adornHeader(this.header)
      this.$http({
        url: this.$http.adornUrl("/personal/dynamicecg/listByTime"),
        method: "get",
        params: this.$http.adornParams({ uid: this.$store.state.personal_module.uid, startTime: this.startTime, endTime: this.endTime }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          console.log("看看数据111");
          console.log(data);
          this.datalist = data.dynamicEcgEntities;
          // 列表显示该条数据的总时长
          var tableData = new Array(this.datalist.length)
          tableData = this.datalist.reverse()
          for (let i = 0; i < tableData.length; i++) {
            var totalTime = new Date(tableData[i].duration * 1000)
            // console.log(totalTime)
            let d = totalTime.getDate() - 1;
            let h = totalTime.getHours() - 8;
            if (h < 0) {
              d = d - 1
              h = 24 + h
            }
            h = h < 10 ? ('0' + h) : h;

            let m = totalTime.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = totalTime.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            if (d != 0) {
              tableData[i].totalTime = d + '天' + h + '小时' + m + '分'; //显示到分
            }
            else if (h == 0) {
              if (m != 0) {
                tableData[i].totalTime = m + '分' + s + '秒';  //显示到秒
              }
              else {
                tableData[i].totalTime = s + '秒';  //显示到秒
              }

            }
            else {
              tableData[i].totalTime = h + '小时' + m + '分' //显示到分
            }
          }
          this.tableData = tableData
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    queryAll() {
      this.getDataList()
    },
    //删除功能。目前简单用假数据代替，还不涉及数据库链接
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableData.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    getDataList() {
      this.loadingShow = true
      if (this.$store.state.module.crossPlatform) {
        console.log("object11111");
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http.adornHeader(this.header)
      this.$http({
        url: this.$http.adornUrl("/personal/dynamicecg/list"),
        method: "get",
        params: this.$http.adornParams({ uid: this.$store.state.personal_module.uid }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          console.log("看看数据");
          // console.log(data);
          this.datalist = data.dynamicEcgEntities;
          // 列表显示该条数据的总时长
          var tableData = new Array(this.datalist.length)
          tableData = this.datalist.reverse()
          for (let i = 0; i < tableData.length; i++) {
            var totalTime = new Date(tableData[i].duration * 1000)
            // console.log(totalTime)
            let d = totalTime.getDate() - 1;
            let h = totalTime.getHours() - 8;
            if (h < 0) {
              d = d - 1
              h = 24 + h
            }
            h = h < 10 ? ('0' + h) : h;

            let m = totalTime.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = totalTime.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            if (d != 0) {
              tableData[i].totalTime = d + '天' + h + '小时' + m + '分'; //显示到分
            }
            else if (h == 0) {
              if (m != 0) {
                tableData[i].totalTime = m + '分' + s + '秒';  //显示到秒
              }
              else {
                tableData[i].totalTime = s + '秒';  //显示到秒
              }

            }
            else {
              tableData[i].totalTime = h + '小时' + m + '分' //显示到分
            }
          }
          this.tableData = tableData

        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },
    handleClose(done) {
      this.broadcastContent = []
      done();
    },
    handleClick(e) {
      console.log(e);
      if (Cookies.get('Treating') == 1) {
        this.$router.push({
          name: 'HistorySingoHolter2Treat',
          query: {
            "dynamicRecordId": e.did,
            "dynamicRecordCreateTime": e.createTime,
            "dynamicRecorDuration": e.duration
          }
        })
      }else if(Cookies.get('Treating') == 2){
        this.$router.push({
          name: 'UserSingoHolter2Treat',
          query: {
            "dynamicRecordId": e.did,
            "dynamicRecordCreateTime": e.createTime,
            "dynamicRecorDuration": e.duration
          }
        })
      } else {
        this.$router.push({
          name: 'HistorySingoHolter2',
          query: {
            "uid": e.uid,
            "dynamicRecordId": e.did,
            "dynamicRecordCreateTime": e.createTime,
            "dynamicRecorDuration": e.duration,
            "remark": e.remark
          }
        })
      }
    },
    handleBroadcast(e) {
      this.showBar = true
      if (this.$store.state.module.crossPlatform) {
        console.log("object11111");
        this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      }
      this.$http.adornHeader(this.header)
      this.dialogVisible = true
      this.pictLoading = true
      // 根据每条心电记录的did获取该条的结论
      this.$http({
        url: this.$http.adornUrl('/personal/dynamicecg/getConclusion'),
        method: 'get',
        params: this.$http.adornParams({ did: e.did })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          // console.log(data.conclusionData)
          this.pictLoading = false
          this.broadcastContent = data.conclusionData
          // 获取到结论后，判断是新还是老的存储形式,新的就处理一下
          if (this.broadcastContent[this.broadcastContent.length - 1] == ',') {
            this.broadcastContent = "[" + this.broadcastContent.substring(0, this.broadcastContent.length - 1) + "]";
          }
          this.broadcastContent = JSON.parse(this.broadcastContent)
          var qtycmap = { 0: "ST-T正常", 1: "T波倒置", 2: "ST段抬高", 3: "ST段压低" }//心梗相关疾病的map
          for (var i = 0; i < this.broadcastContent.length; i++) {
            this.broadcastContent[i].qtyc_map = qtycmap[this.broadcastContent[i].qtyc]
          }
          console.log('看看this.broadcastContent')
          console.log(this.broadcastContent)

          // 记录的总条数
          let len_content = this.broadcastContent.length

          // 统计播报记录非零疾病的数量
          var countDisease = {};
          // 遍历数组
          this.broadcastContent.forEach(obj => {
            // 获取对象的键
            Object.keys(obj)
              // 对每个键执行reduce函数操作，计算非零项数量
              .forEach(key => {
                if (key =='qtyc'){
                  countDisease[key] = (countDisease[key] || 0) + (obj[key] != 0);
                }
                else
                {
                  countDisease[key] = (countDisease[key] || 0) + (obj[key] >50); // 2024-08-07 改为>50
                }

              });
          });

          var chosenKeys = ["xdgs", "xdgh", "xlbq", "fxzb", "jjxzb", "sxzb", "ssxxdgs", "sxxdgs", "fxyb", 'sxyb', 'fc', 'qtyc']
          this.labelName = ['窦性心动过速', '窦性心动过缓', '窦性心律不齐',  '房性早搏', '交界性早搏', '室性早搏', '室上性心动过速', '室性心动过速', '交界性逸博', '室性逸博', '房颤', '心梗相关疾病']
          var chosenResult = {};
          chosenKeys.forEach(key => {
            chosenResult[key] = countDisease[key];
          });
          console.log(chosenResult)
          this.CountData = Object.values(chosenResult)  // 每一项疾病非零项的计数

          // 计算百分比并生成新的数组
          let percentageData = this.CountData.map(value => ((value / len_content) * 100).toFixed(0));
          this.initChart(document.getElementById("BroadcastBar"), this.CountData, this.labelName, percentageData);
          // this.chart.setOption(this.BroadcastBar_option);
          // console.log(this.broadcastContent)
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    routeReport(e) {
      // console.log(e);
      if (Cookies.get('Treating') == 1) {
        this.$router.push({
          name: 'holterReportTreat',
          query: {
            "dynamicRecordId": e.did,
            "dynamicRecordCreateTime": e.createTime,
            "dynamicRecorDuration": e.duration
          }
        })
      } else if(Cookies.get('Treating') == 2){
        this.$router.push({
          name: 'holterReportUser',
          query: {
            "dynamicRecordId": e.did,
            "dynamicRecordCreateTime": e.createTime,
            "dynamicRecorDuration": e.duration
          }
        })
      } else {
        this.$router.push({
          name: 'holterReport',
          query: {
            "dynamicRecordId": e.did,
            "dynamicRecordCreateTime": e.createTime,
            "dynamicRecorDuration": e.duration
          }
        })
      }
    },

    //设置表头行的样式
    tableHeaderColor() {
      return "color:#000 ;font-size:18px;text-align:center";
    },
    //设置播报记录的条件格式，根据数值显示不同颜色

    cellStyle({ row, column, rowIndex, columnIndex }) {
      // var keys = Object.keys(person);
      // console.log(keys)
      // var diseaseList = ['xdgs', 'xdgh', 'xlbq', 'sxtb', 'fxzb', 'jjxzb', 'sxzb', 'ssxxdgs', 'sxxdgs', 'fxyb', 'sxyb', 'fc', 'qtyc']
      // for (var i = 0; i < diseaseList.length; i++) {
      //   // if (columnIndex == i+2) {
      //   //   if (row.diseaseList[i]> 50) {
      //   //     return 'background: #F1948A ';
      //   //   }
      //   // }
      //   console.log(row.diseaseList[i])
      // }
      if (columnIndex == 2) {
        if (row.xdgs > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 3) {
        if (row.xdgh > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 4) {
        if (row.xlbq > 50) {
          return 'background: #F1948A ';
        }
      }
      // if (columnIndex == 5) {
      //   if (row.dxtb > 50) {
      //     return 'background: #F1948A ';
      //   }
      // }
      if (columnIndex == 5) {
        if (row.fxzb > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 6) {
        if (row.jjxzb > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 7) {
        if (row.sxzb > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 8) {
        if (row.ssxxdgs > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 9) {
        if (row.sxxdgs > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 10) {
        if (row.fxyb > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 11) {
        if (row.sxyb > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 12) {
        if (row.fc > 50) {
          return 'background: #F1948A ';
        }
      }
      if (columnIndex == 13) {
        if (row.qtyc_map != 'ST-T正常') {
          return 'background: #F1948A ';
        }
      }

    },

    // 心狗医生--复核
    Check2Doctor(e) {
      console.log(e.userDescribe)
      //医生复核
      this.$http({
        url: this.$http.adornUrl("/personal/relationconsult/createRecord"),
        method: "post",
        data: this.$http.adornData({
          uid: this.$store.state.personal_module.uid, type: "ecg_dynamic", userDescribe: e.userDescribe, recordId: e.did
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log('返回的数据')
          console.log(data)
          e.checkStatus = '待复核'
          this.$message({
            message: '成功向医生发出复核申请',
            type: 'success'
          })
        }
        else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消申请'
        });
      });

    },
    CheckResult(e) {
      this.checkdataList = []
      this.checkdataListVisible = true
      console.log(e)
      this.$http({
        url: this.$http.adornUrl("/personal/relationconsult/getRecord"),
        method: "post",
        data: this.$http.adornData({ type: "ecg_dynamic", recordId: e.did, }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.checkdataList = data.list
          console.log(this.checkdataList)
        }
        else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      })
    },
    getinfo() {
      this.$http({
        url: this.$http.adornUrl('/personal/info/getBasicInfo'),
        method: 'get',
        params: this.$http.adornParams({ uid: this.uid })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据")
          this.realName = data.realName
          // this.age = data.age
          // this.gender = data.gender
          // var genderMap = { 0: "女", 1: "男" }
          // this.gender = genderMap[this.gender]
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
    },
    // 初始化播报记录图表
    initChart(domName, chosenResult, labelName, percentageData) {
      this.chart = this.$echarts.init(domName);
      this.BroadcastBar_option = {
        xAxis: {
          type: 'category',
          data: labelName,
          name: '疾病名称',
          axisLabel: {
            rotate: 60
          }
        },
        yAxis: {
          type: 'value',
          name: '异常百分比(%)',
          max: 100, // 设置Y轴最大值为100
          axisLabel: {
            formatter: '{value}%' // Y轴标签显示百分比，并控制小数位数
          }
        },
        series: [
          {
            data: percentageData,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            label: {
              show: true,
              position: 'top', // 标签显示在柱状图顶部
              formatter: '{c}%', // 显示百分比
              fontSize: 12,
              fontWeight: 'bold',
              color: 'black'
            }
          }
        ]
      }
      this.chart.setOption(this.BroadcastBar_option);

    },
    download_table() {
      // Create a new array with updated key names
      const excel_detail = this.tableData
      // Convert data to a workbook
      const wb = XLSX.utils.book_new();
      const ws1 = XLSX.utils.json_to_sheet(excel_detail);
      XLSX.utils.book_append_sheet(wb, ws1, "详细记录");
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      // Save the workbook
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), this.realName + '_单导联Holter心电检测记录.xlsx');
    },
    updateRemark(e) {
      console.log(e.remark)
      this.$http({
        url: this.$http.adornUrl("/personal/dynamicecg/updateRemark"),   // FIXME:这个接口有点问题
        method: "post",
        params: this.$http.adornParams({
          did: e.did,
          remark: e.remark
        }),
        
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看数据2023年1月9日");
          console.log(data);
          this.$message({
            message: '保存成功',
            type: 'success'
          });
        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    }
  },
  beforeCreate() {
    if (this.$store.state.module.moduleName != 'personal_module' && this.$route.query.uid != null && this.$route.query.uid != undefined) {
      this.userId = this.$route.query.uid
    }
    if (this.$store.state.module.moduleName === 'personal_module' && this.$route.query.uid != null && this.$route.query.uid != undefined) {
      this.userId = this.$route.query.uid
      // this.token = this.$route.query.token
      // Cookies.set('token',this.token)
    }
  },
  created() {
    this.uid = this.$store.state.personal_module.uid
    if (this.$route.query.father_uid != null && this.$route.query.father_uid != undefined) {
      this.father_uid = this.$route.query.father_uid
    }


  },
  mounted() {
    this.getDataList()
    this.getinfo()
    this.isCrossPlatJump = !this.$store.state.module.crossPlatform   // isCrossPlatJump 是否在个人平台 

  },
  computed: {
    // staticRecordId: {
    //   get () { return this.$store.state.personal_module.staticRecordId },
    //   set (val) { this.$store.commit('personal_module/updateStaticRecordId', val) }
    // },
    userId: {
      get() { return this.$store.state.personal_module.uid },
      set(val) { this.$store.commit('personal_module/updateUId', val) }
    },
  },
  beforeRouteLeave(to, from, next) {
    // 导航离开该组件的对应路由时调用
    if (this.father_uid) {
      this.userId = this.father_uid
    }
    next()    //允许跳转页面
  }
};
</script>
<style scoped>
.blank {
  border-radius: 4px;
  height: 18px;
}

.form-name {
  display: inline-block;
  font-weight: bold;
  font-size: 18px;
}

.el-dialog-div {
  height: 60vh;
  overflow: auto;
}

.xingou-blue {
  color: #fff;
  background-color: #428bca;
  border-color: #428bca;
}

.xingou-blue:hover,
.xingou-blue:focus {
  background: #4da1eb;
  border-color: #4da1eb;
  color: #fff
}
</style>
